import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, header, footer, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      localStorage.getItem('JWT')
        ? (
          <div>
            <Component {...props} />
          </div>
        )
        : <Redirect to="/" />
    )}
  />
);

export default PrivateRoute;
