import * as actionTypes from "../../actions/actionTypes/ideali";

const initialState = {
  isFetching: false,
  error: null,
  message: null,
  loginValidateResponse: null,
  OAuthDetailsSavedResponse: null,
  UpdatePlanDetailsSavedResponse: null,
  currentSubscriptionPlanDetails: null,
  lapData: null,
  rowDataResponse: null,
  bestAndSelectedData: null,
  bestAndSelectedTouched: false,
  bestAndSelectedSame: false,
  analyzedChartData: null,
  analyzeChartDataTouched: false,
  OAuthResponse: null,
  getBetaInviteTouched: false,
  betaDataResponse: null,
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_CHECK_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        loginValidateResponse: null,
        error: null
      });
    case actionTypes.LOGIN_CHECK_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.LOGIN_CHECK_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        loginValidateResponse: action.data
      });

    case actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        UpdatePlanDetailsSavedResponse: null,
        currentSubscriptionPlanDetails: null,
        error: null
      });
    case actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.UPDATE_SUBSCRIPTION_PLAN_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        UpdatePlanDetailsSavedResponse: action.data
      });

    case actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        currentSubscriptionPlanDetails: null,
        error: null
      });
    case actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.GET_SUBSCRIPTION_PLAN_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        currentSubscriptionPlanDetails: action.data.response[0]
      });

    case actionTypes.CLEAR_LOGIN_RESPONSE:
      return Object.assign({}, prevState, {
        loginValidateResponse: null,
        lapData: null,
        rowDataResponse: null
      });

    case actionTypes.GET_DATA_REQUEST:
      return Object.assign({}, prevState, { isFetching: true });
    case actionTypes.GET_DATA_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.GET_DATA_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        lapData:
          action.data === "NO DATA"
            ? []
            : Array.isArray(action.data)
              ? action.data
              : JSON.parse(action.data)
      });

    case actionTypes.SEND_ROW_DATA_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        bestAndSelectedData: null,
        bestAndSelectedTouched: false,
        analyzedChartData: null
      });
    case actionTypes.SEND_ROW_DATA_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.SEND_ROW_DATA_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        bestAndSelectedData:
          action.data === "" ? null : JSON.parse(action.data),
        bestAndSelectedTouched: true,
        bestAndSelectedSame: action.data === "" ? true : false
      });

    case actionTypes.CLEAR_BESTANDSELECTED_DATA:
      return Object.assign({}, prevState, {
        bestAndSelectedData: null,
        bestAndSelectedTouched: false
      });

    case actionTypes.SEND_WHATIF_DATA_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        analyzedChartData: null,
        analyzeChartDataTouched: true
      });
    case actionTypes.SEND_WHATIF_DATA_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.SEND_WHATIF_DATA_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        analyzedChartData: JSON.parse(action.data),
        analyzeChartDataTouched: false
      });

    case actionTypes.SAVE_OAUTH_DETALS_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        OAuthResponse: null
      });
    case actionTypes.SAVE_OAUTH_DETALS_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error
      });
    case actionTypes.SAVE_OAUTH_DETALS_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        OAuthResponse: action.data
      });

    case actionTypes.GET_BETA_REQUEST:
      return Object.assign({}, prevState, {
        isFetching: true,
        betaDataResponse: null,
        getBetaInviteTouched: true
      });
    case actionTypes.GET_BETA_REQUEST_FAIL:
      return Object.assign({}, prevState, {
        isFetching: false,
        error: action.error,
        getBetaInviteTouched: false
      });
    case actionTypes.GET_BETA_REQUEST_RESPONSE:
      return Object.assign({}, prevState, {
        isFetching: false,
        betaDataResponse: action.data,
        getBetaInviteTouched: false
      });

    case actionTypes.CLEAR_BETA_REQUEST_RESPONSE:
      return Object.assign({}, prevState, {
        betaDataResponse: null,
      });

    default:
      return prevState;
  }
};
