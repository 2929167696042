import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, header, footer, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <div>
        <Component {...props} />
      </div>
    )}
  />
);

export default PublicRoute;
