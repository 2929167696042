import React, { Suspense, lazy } from "react";
import { BrowserRouter as Routes, Switch } from "react-router-dom";
import PublicRoute from "../../components/publicRoute";
import PrivateRoute from "../../components/privateRoute";
import Spinner from "../../components/spinner";
// import PageNotFound from '../../components/pageNotFound';
import ScrollToTop from "../../components/scrollToTop";

const Signin = lazy(() => import("../signIn/index"));
const ErrorPage = lazy(() => import("../../components/error"));
const Subscription = lazy(() => import("../subscription"));
const Dashboard = lazy(() => import("../dashboard"));
const BestAndSelected = lazy(() => import("../bestAndSelected"));
const LaneVision = lazy(() => import("../laneVision"));
const BetaBuildPage = lazy(() => import("../laneVision/betaBuildPage"));
// const Sample = lazy(() => import('../sample/One'));

const Router = () => (
  <Routes>
    <Suspense fallback={<Spinner />}>
      <Switch>
        <ScrollToTop>
          <PublicRoute exact path="/" component={LaneVision} />
          <PublicRoute
            exact
            path="/a295e0bdde1938d1fbfd343e5a3e569e868e1465"
            component={BetaBuildPage}
          />
          <PublicRoute exact path="/signin" component={Signin} />
          <PublicRoute exact path="/error" component={ErrorPage} />
          <PrivateRoute exact path="/subscription" component={Subscription} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/bestandselected"
            component={BestAndSelected}
          />

          {/* <PublicRoute path="/sample" component={Sample} /> */}
          {/* <PublicRoute component={PageNotFound} /> */}
        </ScrollToTop>
      </Switch>
    </Suspense>
  </Routes>
);
export default Router;
