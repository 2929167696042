import { all, fork } from "redux-saga/effects";
import { takeSubscriptionRequest } from "./subscription";
import {
  takeValidateRequest,
  takeOAuthDetailsSaveRequest,
  takeUpdateSubscriptionPlanDetailsRequest,
  takeGetSubscriptionPlanDetailsRequest,
  takeGetDataRequest,
  takeSendRowDataRequest,
  takeGetWhatIfChartDataRequest,
  takeGetBetaAppRequest
} from "./ideali";

function* root() {
  yield all([
    fork(takeSubscriptionRequest),
    fork(takeValidateRequest),
    fork(takeOAuthDetailsSaveRequest),
    fork(takeUpdateSubscriptionPlanDetailsRequest),
    fork(takeGetSubscriptionPlanDetailsRequest),
    fork(takeGetDataRequest),
    fork(takeSendRowDataRequest),
    fork(takeGetWhatIfChartDataRequest),
    fork(takeGetBetaAppRequest)
  ]);
}

export default root;
