import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import subscriptionReducer from "./subscription";
import idealiReducer from "./ideali";

const reducer = combineReducers({
  subscription: subscriptionReducer,
  form: reduxFormReducer,
  ideali: idealiReducer
});
export default reducer;
