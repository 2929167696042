//Login check through DB
export const LOGIN_CHECK_REQUEST = "users/LOGIN_CHECK_REQUEST";
export const LOGIN_CHECK_REQUEST_FAIL = "users/LOGIN_CHECK_REQUEST_FAIL";
export const LOGIN_CHECK_RESPONSE = "users/LOGIN_CHECK_RESPONSE";

//Save OAuthDetails
export const SAVE_OAUTH_DETALS_REQUEST = "users/SAVE_OAUTH_DETALS_REQUEST";
export const SAVE_OAUTH_DETALS_REQUEST_FAIL =
  "users/SAVE_OAUTH_DETALS_REQUEST_FAIL";
export const SAVE_OAUTH_DETALS_RESPONSE = "users/SAVE_OAUTH_DETALS_RESPONSE";

//Update Plan details
export const UPDATE_SUBSCRIPTION_PLAN_REQUEST =
  "users/UPDATE_SUBSCRIPTION_PLAN_REQUEST";
export const UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAIL =
  "users/UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAIL";
export const UPDATE_SUBSCRIPTION_PLAN_RESPONSE =
  "users/UPDATE_SUBSCRIPTION_PLAN_RESPONSE";

//Get Plan details
export const GET_SUBSCRIPTION_PLAN_REQUEST =
  "users/GET_SUBSCRIPTION_PLAN_REQUEST";
export const GET_SUBSCRIPTION_PLAN_REQUEST_FAIL =
  "users/GET_SUBSCRIPTION_PLAN_REQUEST_FAIL";
export const GET_SUBSCRIPTION_PLAN_RESPONSE =
  "users/GET_SUBSCRIPTION_PLAN_RESPONSE";

//Clear login response
export const CLEAR_LOGIN_RESPONSE = "users/CLEAR_LOGIN_RESPONSE";

//get Table Data
export const GET_DATA_REQUEST = "users/GET_DATA_REQUEST";
export const GET_DATA_REQUEST_FAIL = "users/GET_DATA_REQUEST_FAIL";
export const GET_DATA_RESPONSE = "users/GET_DATA_RESPONSE";

//send Row Data
export const SEND_ROW_DATA_REQUEST = "users/SEND_ROW_DATA_REQUEST";
export const SEND_ROW_DATA_REQUEST_FAIL = "users/SEND_ROW_DATA_REQUEST_FAIL";
export const SEND_ROW_DATA_RESPONSE = "users/SEND_ROW_DATA_RESPONSE";

//clear best and selected data
export const CLEAR_BESTANDSELECTED_DATA = "users/CLEAR_BESTANDSELECTED_DATA";

//send WhatIf Data
export const SEND_WHATIF_DATA_REQUEST = "users/SEND_WHATIF_DATA_REQUEST";
export const SEND_WHATIF_DATA_REQUEST_FAIL =
  "users/SEND_WHATIF_DATA_REQUEST_FAIL";
export const SEND_WHATIF_DATA_RESPONSE = "users/SEND_WHATIF_DATA_RESPONSE";

export const GET_BETA_REQUEST = "users/GET_BETA_REQUEST";
export const GET_BETA_REQUEST_FAIL = "users/GET_BETA_REQUEST_FAIL";
export const GET_BETA_REQUEST_RESPONSE = "users/GET_BETA_REQUEST_RESPONSE";

export const CLEAR_BETA_REQUEST_RESPONSE = "users/CLEAR_BETA_REQUEST_RESPONSE";
