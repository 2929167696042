import { takeEvery, put } from "redux-saga/effects";
import { apiCall } from "../../util";
import * as actionTypes from "../../actions/actionTypes/ideali";
import {
  loginCheckRequestFail,
  loginCheckResponse,
  saveOAuthDetailsRequestFail,
  saveOAuthDetailsResponse,
  updateSubscriptionPlanDetailsRequestFail,
  updateSubscriptionPlanDetailsResponse,
  getSubscriptionPlanDetailsRequestFail,
  getSubscriptionPlanDetailsResponse,
  getDataRequestFail,
  getDataResponse,
  sendRowDataFail,
  sendRowDataResponse,
  sendWhatIfDataRequestFail,
  sendWhatIfDataResponse,
  sendBetaAppRequestFail,
  sendBetaAppResponse
} from "../../actions/ideali";

function* loginCheckRequest(loginDetails) {
  const response = yield apiCall("validateLogin", "POST", loginDetails);
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(loginCheckRequestFail(response.data.status));
    } else {
      window.localStorage.setItem("JWT", response.data.jwt);
      window.localStorage.setItem("firstName", response.data.firstName);
      window.localStorage.setItem("lastName", response.data.lastName);
      yield put(loginCheckResponse(response.data));
    }
  }
}

function* oauthDetailsSaveRequest(oAuthDetailsDetails) {
  const response = yield apiCall("OAuth", "POST", oAuthDetailsDetails);
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(saveOAuthDetailsRequestFail(response.data.status));
    } else {
      window.localStorage.setItem("JWT", response.data.jwt);
      yield put(saveOAuthDetailsResponse(response.data));
    }
  }
}

function* updateSubscriptionPlanDetailsRequest(subscriptionPlanDetails) {
  const response = yield apiCall(
    "ideali/saveSubscription",
    "POST",
    subscriptionPlanDetails
  );
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(updateSubscriptionPlanDetailsRequestFail(response.data.status));
    } else {
      yield put(updateSubscriptionPlanDetailsResponse(response.data));
    }
  }
}

function* getSubscriptionPlanDetailsRequest(getSubscriptionPlanDetails) {
  const response = yield apiCall(
    "ideali/getSubscriptionPlanDetails",
    "POST",
    getSubscriptionPlanDetails
  );
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(getSubscriptionPlanDetailsRequestFail(response.data.status));
    } else {
      yield put(getSubscriptionPlanDetailsResponse(response.data));
    }
  }
}

function* getDataRequestHandler(dataDetails) {
  const response = yield apiCall("ideali/getData", "GET", dataDetails);
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(getDataRequestFail(response.data.error));
    } else {
      yield put(getDataResponse(response.data));
    }
  }
}

function* sendRowDataRequest(dataDetails) {
  const response = yield apiCall("ideali/postDataId", "POST", dataDetails);
  if (response && response.status) {
    if (response.status !== 200 && response.status !== 204) {
      yield put(sendRowDataFail(response.data.error));
    } else {
      yield put(sendRowDataResponse(response.data));
    }
  }
}

function* getWhatIfChartDataRequest(dataDetails) {
  const response = yield apiCall(
    "ideali/getWhatIfChartData",
    "POST",
    dataDetails
  );
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(sendWhatIfDataRequestFail(response.data.error));
    } else {
      yield put(sendWhatIfDataResponse(response.data));
    }
  }
}

function* getBetaAppRequest(dataDetails) {
  const response = yield apiCall("getBetaApp", "POST", dataDetails);
  if (response && response.status) {
    if (response.status !== 200) {
      yield put(sendBetaAppRequestFail(response.data.error));
    } else {
      yield put(sendBetaAppResponse(response.data));
    }
  }
}

export function* takeSendRowDataRequest() {
  yield takeEvery(actionTypes.SEND_ROW_DATA_REQUEST, sendRowDataRequest);
}

export function* takeGetDataRequest() {
  yield takeEvery(actionTypes.GET_DATA_REQUEST, getDataRequestHandler);
}

export function* takeValidateRequest() {
  yield takeEvery(actionTypes.LOGIN_CHECK_REQUEST, loginCheckRequest);
}

export function* takeOAuthDetailsSaveRequest() {
  yield takeEvery(
    actionTypes.SAVE_OAUTH_DETALS_REQUEST,
    oauthDetailsSaveRequest
  );
}

export function* takeUpdateSubscriptionPlanDetailsRequest() {
  yield takeEvery(
    actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST,
    updateSubscriptionPlanDetailsRequest
  );
}

export function* takeGetSubscriptionPlanDetailsRequest() {
  yield takeEvery(
    actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST,
    getSubscriptionPlanDetailsRequest
  );
}

export function* takeGetWhatIfChartDataRequest() {
  yield takeEvery(
    actionTypes.SEND_WHATIF_DATA_REQUEST,
    getWhatIfChartDataRequest
  );
}

export function* takeGetBetaAppRequest() {
  yield takeEvery(actionTypes.GET_BETA_REQUEST, getBetaAppRequest);
}
