import * as actionTypes from "../actionTypes/ideali";

//Login check through DB
export function loginCheckRequest(payload, props) {
  return { type: actionTypes.LOGIN_CHECK_REQUEST, payload, props };
}

export function loginCheckRequestFail(error) {
  return { type: actionTypes.LOGIN_CHECK_REQUEST_FAIL, error };
}

export function loginCheckResponse(data) {
  return { type: actionTypes.LOGIN_CHECK_RESPONSE, data };
}

//Save OAuthDetails
export function saveOAuthDetailsRequest(payload, props) {
  return { type: actionTypes.SAVE_OAUTH_DETALS_REQUEST, payload, props };
}

export function saveOAuthDetailsRequestFail(error) {
  return { type: actionTypes.SAVE_OAUTH_DETALS_REQUEST_FAIL, error };
}

export function saveOAuthDetailsResponse(data, props) {
  return { type: actionTypes.SAVE_OAUTH_DETALS_RESPONSE, data };
}

//Update Plan details
export function updateSubscriptionPlanDetailsRequest(payload, props) {
  return { type: actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST, payload, props };
}

export function updateSubscriptionPlanDetailsRequestFail(error) {
  return { type: actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST_FAIL, error };
}

export function updateSubscriptionPlanDetailsResponse(data) {
  return { type: actionTypes.UPDATE_SUBSCRIPTION_PLAN_RESPONSE, data };
}

//Get Plan details
export function getSubscriptionPlanDetailsRequest(payload, props) {
  return { type: actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST, payload, props };
}

export function getSubscriptionPlanDetailsRequestFail(error) {
  return { type: actionTypes.GET_SUBSCRIPTION_PLAN_REQUEST_FAIL, error };
}

export function getSubscriptionPlanDetailsResponse(data) {
  return { type: actionTypes.GET_SUBSCRIPTION_PLAN_RESPONSE, data };
}

export function clearLoginResponse() {
  return { type: actionTypes.CLEAR_LOGIN_RESPONSE };
}

//get table data
export function getDataRequest() {
  return { type: actionTypes.GET_DATA_REQUEST };
}

export function getDataRequestFail(error) {
  return { type: actionTypes.GET_DATA_REQUEST_FAIL, error };
}

export function getDataResponse(data) {
  return { type: actionTypes.GET_DATA_RESPONSE, data };
}

//send Row Data
export function sendRowDataRequest(props, payload) {
  return { type: actionTypes.SEND_ROW_DATA_REQUEST, props, payload };
}

export function sendRowDataFail(error) {
  return { type: actionTypes.SEND_ROW_DATA_REQUEST_FAIL, error };
}

export function sendRowDataResponse(data) {
  return { type: actionTypes.SEND_ROW_DATA_RESPONSE, data };
}

//clear best and selected data
export function clearBestAndSelectedData() {
  return { type: actionTypes.CLEAR_BESTANDSELECTED_DATA };
}

//send WhatIf Data
export function sendWhatIfDataRequest(payload) {
  return { type: actionTypes.SEND_WHATIF_DATA_REQUEST, payload };
}

export function sendWhatIfDataRequestFail(error) {
  return { type: actionTypes.SEND_WHATIF_DATA_REQUEST_FAIL, error };
}

export function sendWhatIfDataResponse(data) {
  return { type: actionTypes.SEND_WHATIF_DATA_RESPONSE, data };
}

// getBeta App
export function sendBetaAppRequest(payload) {
  return { type: actionTypes.GET_BETA_REQUEST, payload };
}

export function sendBetaAppRequestFail(error) {
  return { type: actionTypes.GET_BETA_REQUEST_FAIL, error };
}

export function sendBetaAppResponse(data) {
  return { type: actionTypes.GET_BETA_REQUEST_RESPONSE, data };
}

export function clearBetaAppResponse() {
  return { type: actionTypes.CLEAR_BETA_REQUEST_RESPONSE };
}
