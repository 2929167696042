// This is a component which restores scroll position in react-router
// Please refer: https://reacttraining.com/react-router/web/guides/scroll-restoration

import React from 'react';
import { withRouter } from "react-router";

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)